@keyframes slide-inout {
  0% {
    left: -100px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 1;
  }
}

.sec01 {
  position: relative;
}

@keyframes fade-inout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes upfromdown {
  0% {
    opacity: 0;
    bottom: -10px;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

.image {
  animation: fade-inout 3s;
}

.container {
  justify-content: center;
  gap: 10px;
}

.scroll {
  position: relative;
  animation: upfromdown 1s;
}

.scroll1 {
  position: relative;
  animation: upfromdown 1s;
}

@keyframes fade-inout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header1 {
  position: relative;
  animation: fade-inout 1.5s;
}