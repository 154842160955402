@keyframes slide-inout {
  0% {
    left: -100px;
    opacity: 0;
  }

  100% {
    left: 0px;
    opacity: 1;
  }
}

.sec01 {
  position: relative;
  animation: slide-inout 2s;
}

@keyframes fade-inout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes upfromdown {
  0% {
    opacity: 0;
    bottom: -10px;
  }

  100% {
    opacity: 1;
    bottom: 0px;
  }
}

.image {
  animation: fade-inout 3s;
}

.container {
  justify-content: center;
  gap: 10px;
}

.headertext {
  font-size: 50px;
}

.headersubtext {
  font-size: 20px;
}

.sec01 {
  font-size: 55px;
  font-weight: bolder;
}

.sec02 {
  font-size: 25px;
}

.parallax {
  transform: translateX(0%) translateY(0%);
  font-size: 60px;
}

.parallax1 {
  font-size: 60px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Imgbg {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.imgtext {
  font-weight: 600;
  text-align: center;
}

.diff {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.diff1 {
  position: relative;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .imgtext {
    font-size: 40px;
  }

  .logo {
    height: 80px;
    width: 80px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .learn {
    display: none;
  }

  .Imgbg {
    align-items: center;
    justify-content: center;
    padding-top: 200px;
    padding-left: 45px;
  }

  .rightPadding {
    align-items: center;
    justify-content: center;
    padding-right: 85px;
  }
}

@media only screen and (min-width: 768px) {
  .topPadding {
    padding-top: 50px;
  }
}

* {
  box-sizing: border-box
}

@media only screen and (min-width: 1100px) {
  .scrlbar1 {
    padding-top: 175px;
    padding-bottom: 25px;
  }
}