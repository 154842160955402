@keyframes upfromdown {
    0%{
		opacity: 0;
		bottom: -10px;
	}
    100%{
		opacity: 1;
		bottom: 0px;
	}
}
@keyframes upfromdown1 {
    0%{
		opacity: 0;
		bottom: -25px;
	}
    100%{
		opacity: 1;
		bottom: 0px;
	}
}

.member{
	position: relative;
	animation: upfromdown 1s;
}
.member1{
	position: relative;
	animation: upfromdown1 1.25s;
}