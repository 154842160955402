@media only screen and (max-width: 768px) {
    .topPadding {
        padding-top: 125px;
    }
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
    .topPadding {
        padding-top: 145px;
    }
}